import axios from "axios";
import React,{useEffect, useState} from "react";

const GameStats = () => {
  const [stats, setStats] = useState([]);

  useEffect(()=>{
    console.log("useEffect: GameStats Load");
    const getStats = async () => {
      const res = await axios.post(
        "https://reqres.in/api/users"
      );
      const data = res.data;
      setStats(data);
    };
    getStats();
  },[stats.length])
  return (
    <>
      <div className="row mt-3">
        <h1>game stats</h1>
        <div className="col-md-4 col-12 bord">
          <h2> game played</h2>
          <div className="row">
            <div className="col-md-6 col-6">bronze</div>
            <div className="col-md-6 col-6">
              {stats.length > 0 ? stats[0].title : ""}
            </div>
            <div className="col-md-6 col-6">silver</div>
            <div className="col-md-6 col-6">null</div>
            <div className="col-md-6 col-6">gold</div>
            <div className="col-md-6 col-6">null</div>
            <div className="col-md-6 col-6">diamond</div>
            <div className="col-md-6 col-6">null</div>
            <div className="col-md-6 col-6">expired</div>
            <div className="col-md-6 col-6">null</div>
            <div className="col-md-6 col-6">calcelded</div>
            <div className="col-md-6 col-6">null</div>
          </div>
        </div>
        <div className="col-md-4 col-12 bord">
          <h2> card sold</h2>
          <div className="row">
            <div className="col-md-6 col-6">bronze</div>
            <div className="col-md-6 col-6">null</div>
            <div className="col-md-6 col-6">silver</div>
            <div className="col-md-6 col-6">null</div>
            <div className="col-md-6 col-6">gold</div>
            <div className="col-md-6 col-6">null</div>
            <div className="col-md-6 col-6">diamond</div>
            <div className="col-md-6 col-6">null</div>
          </div>
        </div>
        <div className="col-md-4 col-12 bord">
          <h2> user stats</h2>
          <div className="row">
            <div className="col-md-6 col-6">total users</div>
            <div className="col-md-6 col-6">null</div>
            <div className="col-md-6 col-6">blocked user</div>
            <div className="col-md-6 col-6">null</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(GameStats);
