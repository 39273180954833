import React, { useEffect, useState } from "react";
import axios from "axios";


function ListAdminHost() {
  const AdminList = [
    {
      Wallet: "place holder",
    },
    {
      Wallet: "place holder",
    },
   
  ];
  const HostList = [
    {
      Wallet: "place holder",
    },
    {
      Wallet: "place holder",
    },
    {
      Wallet: "place holder",
    },
    {
      Wallet: "place holder",
    },
    {
      Wallet: "place holder",
    },
    {
      Wallet: "place holder",
    },
    {
      Wallet: "place holder",
    },
    {
      Wallet: "place holder",
    },
    {
      Wallet: "place holder",
    },
    {
      Wallet: "place holder",
    },
  ];




  const mapAdmin = () =>
    AdminList.map((AdminList) => (
      <li key={AdminList.Wallet}>{AdminList.Wallet}</li>
    ));
    const mapHost = () =>
    HostList.map((HostList) => (
      <li key={HostList.Wallet}>{HostList.Wallet}</li>
    ));
  return (
    <div className="row mt-3">
      <h1>admin & host list</h1>
      <div className="col-md-6 col-12 bord">
        <h2> admin list</h2>
        <div className="col-md-12 col-12 maplist scrollbarustclassim">
          <ul>{mapAdmin()}</ul>
        </div>
      </div>
      <div className="col-md-6 col-12 bord">
        <h2>host list</h2>
        <div className="col-md-12 col-12 maplist scrollbarustclassim">
          <ul>{mapHost()}</ul>
        </div>
      </div>
    </div>
  );
}

export default ListAdminHost;
