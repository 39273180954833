import React, { useEffect, useState } from "react";
import { formatAddress } from "../utility/Utils.js";
import { useMetaMask } from "../utility/hooks/useMetaMask.js";


const TeamShare = () => {
  const {contractAndAuth} = useMetaMask();
  const [team1wallet, setTeam1wallet] = useState(null);
  const [team2wallet, setTeam2wallet] = useState(null);

  useEffect(() => {
    console.log("useEffect: TeamShare Load");
    if (contractAndAuth.contract) {
      (async () => {
        const team1Address = await contractAndAuth.contract.team1Address();
        const team2Address = await contractAndAuth.contract.team2Address();
        
        setTeam1wallet(team1Address);
        setTeam2wallet(team2Address);
      })();
    }
  },[contractAndAuth, team1wallet, team2wallet])

    return (
      <>
        <div className="row ">
          <h1>team shares</h1>
          <div className="col-md-5 col-12 bord test">
            <h2 className="brt">team1 - baran</h2>
            <div className="row">
              <div className="col-md-6 col-12 "><p>total wons</p></div>
              <div className="col-md-6 col-12 ">
                <span>BALANCE</span> MATIC
              </div>
              <div className="col-md-6 col-12 "><p>wallet adress</p></div>
              <div className="col-md-6 col-12 ">{team1wallet && formatAddress(team1wallet)}</div>
            </div>
          </div>
          <div className="col-md-2 col-12 w-10"></div>
        
          <div className="col-md-5 col-12 bord test">
          <h2 className="brt"> team2 - 10seclabs</h2>
            <div className="row">
              <div className="col-md-6 col-12 "><p>total wons</p></div>
              <div className="col-md-6 col-12 ">
                <span>BALANCE</span> MATIC
              </div>
              <div className="col-md-6 col-12 "><p>wallet adress</p></div>
              <div className="col-md-6 col-12 ">{team2wallet && formatAddress(team2wallet)}</div>
            </div>
          </div>
        </div>
      </>
    );
  }

export default React.memo(TeamShare);
