import React, { useState, useEffect } from "react";
import TeamShare from "./components/TeamShare.js";
import Funds from "./components/Funds.js";
import AdminHost from "./components/AdminHost.js";
import GameStats from "./components/GameStats.js";
import PlatformFees from "./components/PlatformFee.js";
import Header from "./components/Header.js";
import ToastP from "./components/ToastP.js";
import GameSet from "./components/GameSet.js";
import ListAdminHost from "./components/ListAdminHost.js";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useMetaMask } from "./utility/hooks/useMetaMask";

function App() {
  const { wallet, contractAndAuth } = useMetaMask();
  const [deployer, setDeployer] = useState(false);

  const checkNetwork = async () => {
    try {
      console.log("Checking network...");
      if (
        wallet.accounts[0] &&
        window.ethereum.networkVersion !== process.env.REACT_APP_NETWORKVERSION
      ) {
        console.log(
          `Changing network to ${process.env.REACT_APP_NETWORKNAME}...`
        );
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: process.env.REACT_APP_NETWORKID }],
        });
        console.log(`Network changed to ${process.env.REACT_APP_NETWORKNAME}.`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkNetwork();
    console.log("useEffect: App Load");

    try {
      if (contractAndAuth.contract && wallet.accounts.length > 0) {
        (async () => {
          const deployer = await contractAndAuth.contract.deployer();
          if (wallet.accounts[0].toLowerCase() === deployer.toLowerCase()) {
            setDeployer(true);
            console.log("adasdadsa");
          } else setDeployer(false);
        })();
      }
    } catch (error) {
      console.log(error);
    }
  }, [wallet.accounts, contractAndAuth, deployer]);

  return (
    <div className="container">
      <Header />
      {(wallet.accounts.length > 0 && contractAndAuth.isAdmin) || deployer ? (
        <>
          <Funds />
          <TeamShare />
          <AdminHost />
          <ListAdminHost />
          <GameSet />
          <GameStats />
          <PlatformFees />
        </>
      ) : !contractAndAuth.isAdmin || !deployer ? (
        <div className="revert">
          <ToastP />
        </div>
      ) : null}
    </div>
  );
}

export default App;


// prop. ile hangi toast olacağını belirle.(error),(tx toast)
// prop ile toast.header göndernpm run start:prod