import React from "react";
import { useMetaMask } from "../utility/hooks/useMetaMask.js";
import { formatAddress } from "../utility/Utils.js";

const Header = () => {
  const { wallet, connectMetaMask } = useMetaMask();
return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-12 ">
            <h1>Jammy Admin Panel</h1>
          </div>
          <div className="col-md-4 col-12">
            {!wallet.accounts[0] ? (
              <button onClick={() => connectMetaMask()}>Connect Wallet</button>
            ) : (
              <button id="disconn">
                {formatAddress(wallet.accounts[0])}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Header);
