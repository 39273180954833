import React, { useRef, useState } from "react";
import { useMetaMask } from "../utility/hooks/useMetaMask";
import axios from "axios";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";


const AdminHostSet = () => {
 
 
  const { contractAndAuth } = useMetaMask();
  const setadminRef = useRef();
  const sethostRef = useRef();
  const [show, setShow] = useState(false);
  const [showTx, setShowTx] = useState(null);
  const delAdminRef = useRef();
  const delHostRef = useRef();

  const deployerAuth = async (adres, status) => {
    try {
      if (typeof window.ethereum !== "undefined") {
        const tx = await contractAndAuth.contract.setAdmin(adres, status)
          .then((res) => {
            console.log(res);
            // let adminlist = { admin: setadminRef.current.value };
            // axios.post("http://localhost:4000/api/admin-list/", adminlist, {
            //     headers: { "Content-Type": "application/json" },
            //   })
              
            //   .then((response) => {
            //     console.log(response.data);
            //     setShowTx(res.hash);
            //     setShow(true);
            //   });
              
            // return res.hash;
          })
          
      }
    } catch (error) {
      console.error(error);
    }
  };
 

  const adminAuth = async (adres, status) => {
    try {
      if (typeof window.ethereum !== "undefined") {
        const tx = await contractAndAuth.contract.setAdmin(adres, status)
          .then((res) => {
            console.log(res);
            // let adminlist = { admin: setadminRef.current.value };
            // axios.post("http://localhost:4000/api/admin-list/", adminlist, {
            //     headers: { "Content-Type": "application/json" },
            //   })
              
            //   .then((response) => {
            //     console.log(response.data);
            //     setShowTx(res.hash);
            //     setShow(true);
            //   });
              
            // return res.hash;
          })
          .catch((err) => {
           
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const hostAuth = async (adres,status) => {
    try {
      if (typeof window.ethereum !== "undefined") {
        const tx = await contractAndAuth.contract.setHost(adres, status)
          .then((res) => {
            console.log(res);
            // let hostlist = { admin: sethostRef.current.value };
            // axios.post("http://localhost:4000/api/admin-list/", hostlist, {
            //     headers: { "Content-Type": "application/json" },
            //   })
            //   .then((response) => {
            //     console.log(response.data);
            //     setShowTx(res.hash);
            //     setShow(true);
            //   });
              
            // return res.hash;
          })
          .catch((err) => {
            // console.log(err);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  
  return (
    <div className="row mt-3 spaces">
      <ToastContainer // admin
        className="p-3"
        position="middle-center"
        style={{ zIndex: 1, position:'fixed' }}
      >
        <Toast onClose={() => setShow(false)} show={show} delay={5000} bg="success" autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Transaction Hash</strong>
            <small>Success</small>
          </Toast.Header>
          <a href={'https://aaa.com/'+showTx}><Toast.Body className="text-white">https://xxx.com/{showTx}</Toast.Body></a>
        </Toast>
      </ToastContainer>
    
      <h1>admin & host set</h1>
      <div className="col-md-6 col-12 bord ">
        <div className="col-md-12 col-12">
          <h2>admin set</h2>
        </div>
        <div className="row ">
          <div className="col-md-8 col-12 w-48">
            <input type="text" placeholder="wallet address" ref={setadminRef} />
          </div>
          <div className="col-md-4 col-12 ">
            <button onClick={() => adminAuth(setadminRef.current.value, true)}>
              Submit
            </button>
          </div>
          <div className="col-md-8 col-6 mt-3 ">
            <p>total admins</p>
          </div>
          <div className="col-md-4 col-6 mt-3">total admin count</div>
          
          <div className="col-md-12 col-12 mt-3"> 
            <h2 className="brt">delete admin</h2>
          </div>

          <div className="col-md-8 col-12">
            <input type="text" placeholder="wallet address" ref={delAdminRef} />
          </div>
          <div className="col-md-4 col-12 ">
          <button
              onClick={() => deployerAuth(delAdminRef.current.value, false)}>Delete</button>
          
          </div>
          
          
          
          
        </div>
      </div>
    
      <div className="col-md-6 col-12 bord ">
        <div className="col-md-12 col-12">
          <h2>host set</h2>
        </div>
        <div className="row">
          <div className="col-md-8 col-12 ">
            <input
              type="text"
              placeholder="wallet address"
              className="admininput"
              ref={sethostRef}
            />
          </div>
          <div className="col-md-4 col-12 ">
            <button onClick={() => hostAuth(sethostRef.current.value, true)}>
              Submit
            </button>
          </div>
          <div className="col-md-8 col-6 mt-3 ">
            <p>total host user</p>
          </div>
          <div className="col-md-4 col-6 mt-3 ">total host count</div>
          <div className="col-md-12 col-12 mt-3">
            <h2 className="brt">del host</h2>
          </div>

          <div className="col-md-8 col-12 ">
          <input
              type="text"placeholder="wallet address"className="admininput"ref={delHostRef}/>
          </div>
          <div className="col-md-4 col-12 ">
            <button
              onClick={() => hostAuth(delHostRef.current.value, false)}>Delete</button>
          </div>
         
          
         
        </div>
      </div>
    </div>
  );
};

export default React.memo(AdminHostSet);
