import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MetaMaskContextProvider } from "./utility/hooks/useMetaMask";
import {MetaMaskError} from "./components/MetaMaskError"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MetaMaskContextProvider>
   <App />
   <MetaMaskError />
  </MetaMaskContextProvider>
 
);

reportWebVitals();