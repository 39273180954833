import React, {useState} from "react";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";


const ToastP = () => {

  const [ show, setShow] = useState(true)

  return (
    <>
      <div >
        <ToastContainer
          className="p-3 reverted"
          position="middle-center"
          style={{ zIndex: 1, position: "fixed"}}
        >
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={Infinity}
            bg="danger"
            autohide
          >
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto mx-auto">Access Denied</strong>
             
            </Toast.Header>
            <Toast.Body className="text-white text-center ">Your not authorized</Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
    </>
  );
};

export default ToastP;
