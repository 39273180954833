import React from "react";

const PlatformFees = () => {
  
    return (
      <>
        <div className="row mt-3">
          <h1>platform fee</h1>
          <div className="row">
            <div className="col-md-4 col-12 bord">
             <h2> rewards</h2>
              <div className="row">
                <div className="col-md-6 col-6">daily bonus</div>
                <div className="col-md-6 col-6">null</div>
                <div className="col-md-6 col-6">quest</div>
                <div className="col-md-6 col-6">null</div>
              </div>
            </div>
            <div className="col-md-4 col-12 bord">
            <h2> gas fee</h2>
              <div className="row">
                <div className="col-md-6 col-6">tx fee</div>
                <div className="col-md-6 col-6">null</div>
                <div className="col-md-6 col-6">link</div>
                <div className="col-md-6 col-6">null</div>
              </div>
            </div>
            <div className="col-md-4 col-12 bord">
            <h2> platform revenues</h2>
              <div className="row">
                <div className="col-md-6 col-6">chat gift</div>
                <div className="col-md-6 col-6"></div>
                <div className="col-md-6 col-6">redraw Cards</div>
                <div className="col-md-6 col-6"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
export default PlatformFees;
