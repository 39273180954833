import React, { useRef } from "react";
import { useMetaMask } from "../utility/hooks/useMetaMask";
import { ethers } from "ethers";
import { create5x5, arrayToHex } from "./CreateCards";

function GameSet() {
  const cardMockRef = useRef();


  const batchAddCards = async () => {
    try {
      if (cardMockRef.current.value > 0  && typeof window.ethereum !== "undefined") {
        const mockCards = [];
        for (let i = 0; i < cardMockRef.current.value; i++) {
          mockCards.push(create5x5());
        }

        const newCards = mockCards.map((c) =>
          ethers.BigNumber.from(arrayToHex(c))
        );
        const tx = await contractAndAuth.contract.batchAddCards(newCards);
        const receipt = await tx.wait();
        console.log("receipt:", receipt.transactionHash);

       
      }
    } catch (error) {
      console.error(error);
    }
  };


  const { contractAndAuth } = useMetaMask();
  return (
    <div className="row justify-content-center mt-3">
      <div className="col-md-6 col-12">
        <h1>game settings</h1>
        <div className="row bord">
          <div className="col-md-12 col-12 mt-3">
            <h2 className="brt">card setting</h2>
          </div>
          <div className="col-md-4 col-6 mt-3">
            <p>Add Card Func</p>
          </div>
          <div className="col-md-4 col-6 mt-3">
            <input
              type="text"
              placeholder="number"
              className="admininput"
              ref={cardMockRef}
            />
          </div>
          <div className="col-md-4 col-12 mt-3 ">
            <button onClick={batchAddCards}>Add</button>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default GameSet;
