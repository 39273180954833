import React, { useEffect, useRef, useState } from "react";
import { formatAddress } from "../utility/Utils.js";
import { useMetaMask } from "../utility/hooks/useMetaMask.js";
import { BigNumber, utils } from "ethers";

const Funds = () => {
  const { contractAndAuth } = useMetaMask();
  const [pendingFunds, setPendingFunds] = useState(0);
  const amountRefPend = useRef();

  const Withdraw = async (amount) => {
    try {
      if (typeof window.ethereum !== "undefined") {
        console.log(amount);
        const tx = await contractAndAuth.contract.withdraw(amount);
        const receipt = await tx.wait();
        console.log("receipt:", receipt);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log("useEffect: Funds Load");
    (async () => {
      let ethBalance = utils.formatEther(
        BigNumber.from(await contractAndAuth.contract.pendingFunds())
      );
      ethBalance = (+ethBalance).toFixed(2);

      setPendingFunds(ethBalance);
    })();
  }, [contractAndAuth]);

  return (
    <>
      <h1> funds</h1>
      <div className="row bord mt-3">
        <div className="col-md-6 col-6 mt-3 ">
          <p>Bingo v1 Contract Address:</p>
        </div>
        <div className="col-md-6 col-6 mt-3 ">
          {formatAddress(process.env.REACT_APP_CONTRACT_ADDRESS)}
        </div>
        <div className="col-md-3 col-12  mt-3">
          <p>pendingfunds</p>
        </div>
        <div className="col-md-3 col-12 mt-3 ">
          "{pendingFunds}"MATIC
        </div>
        <div className="col-md-3 col-12 mt-3">
          <input type="number" placeholder="Amount" ref={amountRefPend} />
        </div>
        <div className="col-md-3 col-12 mt-3 ">
          <button
            onClick={() => Withdraw(amountRefPend.current.value)}
          >
            <span>Withdraw</span>
          </button>
        </div>

        <div className="col-md-6 col-12 mt-3 ">
          <p>marketing wallet adress</p>
        </div>
        <div className="col-md-6 col-12 mt-3">
          <p>
            <span>"{pendingFunds}"</span>MATIC
          </p>
        </div>
      </div>
    </>
  );
};

export default React.memo(Funds);
